//ValidateUrl
//var curUrl = window.location.href;
//if ((curUrl.indexOf('<') > -1) || (curUrl.indexOf('>') > -1) || (curUrl.indexOf('%') > -1) || (curUrl.indexOf('(') > -1) || (curUrl.indexOf('\"') > -1) || (curUrl.indexOf('\'') > -1)) { window.location.replace("/script/main/notfoundstatic.asp"); }
//Privacy
function popupWarning(){var b=window.open("/script/main/popup_privacy.asp","privacy_window","width=325,height=175,resizable=no,toolbar=no,left=10,top=10");if(b){b.focus()}};
//OO Cookie
function OODomainCookieWrite(a,c,b,d){if(d!=null&&d!=""){document.cookie=escape(c)+"="+escape(b)+"; expires="+(d)+"; domain="+a+"; path=/"}else{document.cookie=escape(c)+"="+escape(b)+"; domain="+a+"; path=/"}}function OODomainCookieRead(b){var a=new RegExp(escape(b)+"=([^;]+)");if(a.test(document.cookie+";")){a.exec(document.cookie+";");return unescape(RegExp.$1)}else{return false}};
//ToolTip
this.tooltip=function(){xOffset=10;yOffset=20;$("a.tooltip").hover(function(a){this.t=this.title;this.title="";$("body").append("<p id='tooltip'>"+this.t+"</p>");$("#tooltip").css("top",(a.pageY-xOffset)+"px").css("left",(a.pageX+yOffset)+"px").css("opacity","0.8").fadeIn("fast")},function(){this.title=this.t;$("#tooltip").remove()});$("a.tooltip").mousemove(function(a){$("#tooltip").css("top",(a.pageY-xOffset)+"px").css("left",(a.pageX+yOffset)+"px")})};
//ToolTipImage
this.tooltipImage=function(){xOffset=100;yOffset=20;$("a.tooltipimg").hover(function(a){this.t=this.title;this.title="";$("body").append("<p id='tooltipimg'><img src='"+this.t+"'></p>");$("#tooltipimg").css("top",(a.pageY-yOffset)+"px").css("left",(a.pageX+xOffset)+"px").css("opacity","1.0").fadeIn("fast")},function(){this.title=this.t;$("#tooltipimg").remove()});$("a.tooltipimg").mousemove(function(a){$("#tooltipimg").css("top",(a.pageY-yOffset)+"px").css("left",(a.pageX+xOffset)+"px")})};$(document).ready(function(){tooltip();tooltipImage()});
//Page Refresh
function refreshPageView(tabNum){if(typeof(bIsDFPAdTag)!="undefined"){webmd.metrics.createPageviewId();webmd.ads.refresh()}wmdPageview(s_pagename,tabNum?s_pagenum+"_"+tabNum:s_pagenum)};
//Ad Refresh
function refreshAdView(strAd){var transTileId=Math.round(99999999*Math.random());var objAd=document.getElementById(strAd);var okToLoop=0;if(objAd){var theObjects=objAd.getElementsByTagName("*");for(i=0;i<=theObjects.length-1;i++){if(theObjects[i].id.indexOf("Ad_Iframe")!=-1){okToLoop+=1}}if(okToLoop==1){while(theObjects.length>1){for(i=0;i<=theObjects.length-1;i++){if(theObjects[i].id.indexOf("Ad_Iframe")<0){try{objAd.removeChild(theObjects[i])}catch(e){}}}}}iframe=objAd.getElementsByTagName("iframe")[0];adScript=objAd.getElementsByTagName("script")[0];if(!iframe){var strHTML=adScript.src}else{var strHTML=iframe.src}strHTML=strHTML.replace(new RegExp("transactionID=[0-9]+"),"transactionID="+transTileId);strHTML=strHTML.replace(new RegExp("tile=[0-9]*"),"tile="+transTileId);if(!iframe){adScript.src=strHTML}else{iframe.src=strHTML}}};
//Continue Reading
$(function () { if ($('#cntid')) { var contHref = $('.nextPage').attr('href'); if (contHref == undefined) contHref = $('.paginationCellNext a').attr('href'); if (contHref == undefined) contHref = $('.jp-next').attr('href'); if (contHref == undefined) contHref = $('.nextPageAP').attr('href'); if (contHref != undefined) $('#cntid').html('&nbsp;<a href="' + contHref + '" onclick="wmdTrack(\'cont\');" class="continue">Continue Reading</a>') } });
//Pointroll support
$(function(){window.onscroll=function(){var bodyScrL=$("body").scrollLeft();if(bodyScrL>0){$("#box").hide()}else{$("#box").show()}}});
// modal window
var modalWin=function(){var that=this;this.modal=function(){var defaults={elem:'',content:'',height:'',width:'',track:'',refreshPageViewOnClose:false};if(arguments[0]&&typeof arguments[0]==='object'){this.options=$.extend(defaults,arguments[0])}};modal.prototype.init=function(){var options={elem:this.options.elem,content:this.options.content,height:this.options.height,width:this.options.width,track:this.options.track,refreshPageViewOnClose:this.options.refreshPageViewOnClose},addOverlay=function(){$('<div class="blockPage"></div>').appendTo('body');$('.blockPage').css({'height':$('body').height(),'width':$('body').width()})},addStyles=function(){var winPos=$(window).scrollTop(),winWidth=window.innerWidth,posTop=winPos+50;$('.modalBox').css({'display':'none','height':options.height+'px','width':options.width+'px','top':posTop+'px','left':((winWidth-options.width)/2)+'px'});if(options.content.indexOf('iframe')==1){$('.innerBox iframe').css({'height':(options.height)+'px','width':(options.width)+'px'})}},addModal=function(){$('<div class="modalBox"><div class="logo">MedicineNet.<span>com</span></div><a href="javascript:void(0);" class="modalClose"></a><div class="innerBox">'+options.content+'</div></div>').insertAfter('.blockPage');$('.modalClose,.blockPage').on('click',function(){closeModal.apply(this)})},actModal=$(this.options.elem).on('click',function(){addOverlay();addModal();addStyles();$('.modalBox').fadeIn();if(options.track!=''){wmdTrack(options.track)}}),closeModal=function(){if(options.refreshPageViewOnClose){$('body').scrollTop(0);refreshPageView()}$('.modalBox,.blockPage').fadeOut('medium',function(){$(this).remove()})}}}();
this.closeModal = function() {
    $('.modalBox,.blockPage').fadeOut('medium', function() {
        $(this).remove()
    })
}
