$(document).ready(function () {

    // Owl Carousel
    if ($('#owl-slideTop').length) {
        $('#owl-slideTop').owlCarousel({
            items: 3,
            stagePadding: 100,
            loop: true,
            margin: 10
        })
    };


    // OLD Owl Carousel
    $("#owl-imgCol-slide").owlCarousel({
        items: 2,
        slideBy: 2,
        loop: true
    });


    // lite TOC 
    if ($('.apPage .toc_lite').length) {  

        $('.apPage .toc_lite').on('mouseenter', '.owl-carousel>li>a', function() {
            $(this).parents('.toc_lite').find('.owl-carousel>li>a').removeClass('open'),
            $(this).parents('.toc_lite').find('.owl-carousel>li>.sub').fadeOut(0),
            $(this).addClass('open'),
            $(this).siblings('.sub').fadeIn(0)
        });

        $('.apPage .toc_lite').on('mouseleave', function() {
            $(this).find('.owl-carousel>li>a').removeClass('open'),
            $(this).find('.owl-carousel>li>.sub').fadeOut(0)
        });


        // carousel TOC in mini menu
        $('#headline .toc_lite .sub').remove(); 

        $('#headline .toc_lite .owl-carousel').owlCarousel({
            nav:true,
            navText: ["<span class='icon-arrow-right2'></span>", "<span class='icon-arrow-right2'></span>"],
            dots: false,
            margin:5,
            slideBy: 10,
            responsive:{
                980:{
                    items:3
                },
                1200:{
                    items:4
                },
                1400:{
                    items:5
                },
                1600:{
                    items:6
                }
            }
        });

        $('#headline .toc_lite .owl-carousel .owl-nav').on('mouseenter', '.owl-next', function() {
            $(this).parents('.owl-carousel').trigger('next.owl.carousel')
        });

        $('#headline .toc_lite .owl-carousel .owl-nav').on('mouseenter', '.owl-prev', function() {
            $(this).parents('.owl-carousel').trigger('prev.owl.carousel')
        });

        $('#headline .toc_lite .owl-carousel').on('click', 'a', function() {       
            $(this).parents('body').find('#headline .miniBtn').children('.icon-search, .icon-menu').removeClass('icon-close').fadeIn();
            $(this).parents('body').children('header').children('.masterSub, .searchBar, .shade').removeClass('on');
        });

    };


    // article
    if ($('#article-wrapper').length) {

        if ($('#TopOfContent').length) {
            var $push = $('#TopOfContent').height()
        } else {
            var $push = $('#headline').height()
        };
        
        $(window).scroll(function () {
            if ($(window).scrollTop() > $(window).height()) {
                $('header').addClass('mini');                
                $('#article-wrapper>.content>article, #article-wrapper>.content>.stickyColRight').css('margin-top',$push);
            } else {
                $('#headline').children('.miniBtn').find('.icon-search, .icon-menu').removeClass('icon-close').css("display", "");   
                $('header').removeClass('mini').children('.masterSub, .searchBar, .shade').removeClass('on');       
                $('#article-wrapper>.content>article, #article-wrapper>.content>.stickyColRight').css('margin-top','0');
            }
        });

        $('header #headline').on({
            click: function () {                               
                $(this).siblings('.miniBtn').children('.icon-search, .icon-menu').removeClass('icon-close').fadeIn();
                $(this).parents('body').children('header').children('.masterSub, .searchBar, .shade').removeClass('on');
                $(this).toggleClass('dropdown');
            },
            mouseleave: function () {
                $(this).removeClass('dropdown')
            }
        }, '.toc');        

        $('header #headline').on('click', '.miniBtn .icon-menu', function() {
            $(this).siblings('.icon-search').fadeToggle();
            $(this).toggleClass('icon-close');
            $(this).parents('body').children('header').children('.masterSub, .shade').toggleClass('on');
        });

        $('header #headline').on('click', '.miniBtn .icon-search', function() {
            $(this).siblings('.icon-menu').fadeToggle();
            $(this).toggleClass('icon-close');            
            $(this).parents('body').children('header').children('.searchBar, .shade').toggleClass('on');
        });

        $('#refs').on('click', function () {
            $(this).next().slideToggle()
        })
    };


    // comments
    if ($('.sideBox.comments').length) {
        var $comments = $(".sideBox.comments");
        $comments.each(function() {
            var $Wrapper = $(this).find("ul");
            if ($Wrapper.height() < 500) {
                $Wrapper.css("height", "auto");
                $(this).find(".more,.gradient").css("display", "none")
            } else {
                $Wrapper.css("height", "500px");
                $(this).find(".more").children("span").on("click", function() {
                    var $ParWrapper = $(this).parents('.sideBox.comments').find('ul'),
                        $CurHeight = $ParWrapper.height();
                    if ($CurHeight == 500) {
                        $ParWrapper.css('height', 'auto');
                        $ParWrapper.children(".gradient").fadeOut();
                        $(this).addClass('active')
                    } else {
                        $ParWrapper.animate({
                            height: 500
                        }, 200);
                        $ParWrapper.children(".gradient").fadeIn();
                        $(this).removeClass('active');
                        $('body, html').stop().animate({
                            scrollTop: $(this).parents('.sideBox.comments').offset().top 
                        }, 600)
                    };
                    return false
                })
            }
        })
    };


    // related article
    if ($('.sideBox.thumbnails').length) {
        var $relatedArticle = $(".sideBox.thumbnails");
        $relatedArticle.each(function() {
            var $Wrapper = $(this).find("ul");
            if ($Wrapper.height() < 450) {
                $Wrapper.css("height", "auto");
                $(this).find(".more,.gradient").css("display", "none")
            } else {
                $Wrapper.css("height", "450px");
                $(this).find(".more").children("span").on("click", function() {
                    var $ParWrapper = $(this).parents('.sideBox.thumbnails').find('ul'),
                        $CurHeight = $ParWrapper.height();
                    if ($CurHeight == 450) {
                        $ParWrapper.css('height', 'auto');
                        $ParWrapper.children(".gradient").fadeOut();
                        $(this).addClass('active')
                    } else {
                        $ParWrapper.animate({
                            height: 450
                        }, 200);
                        $ParWrapper.children(".gradient").fadeIn();
                        $(this).removeClass('active');
                        $('body, html').stop().animate({
                            scrollTop: $(this).parents('.sideBox.thumbnails').offset().top 
                        }, 600)
                    };
                    return false
                })
            }
        })
    };


    // funded promo
    if ($('#fundVidPromo').length) {
        var $relatedArticle = $("#fundVidPromo");
        $relatedArticle.each(function() {
            var $Wrapper = $(this).find(".wrapper");
            if ($Wrapper.height() < 220) {
                $Wrapper.css("height", "auto");
                $(this).find(".more,.gradient").css("display", "none")
            } else {
                $Wrapper.css("height", "220px");
                $(this).find(".more").children("span").on("click", function() {
                    var $ParWrapper = $(this).parents('#fundVidPromo').find('.wrapper'),
                        $CurHeight = $ParWrapper.height();
                    if ($CurHeight == 220) {
                        $ParWrapper.css('height', 'auto');
                        $ParWrapper.children(".gradient").fadeOut();
                        $(this).addClass('active')
                    } else {
                        $ParWrapper.animate({
                            height: 220
                        }, 200);
                        $ParWrapper.children(".gradient").fadeIn();
                        $(this).removeClass('active');
                        $('body, html').stop().animate({
                            scrollTop: $(this).parents('#fundVidPromo').offset().top 
                        }, 600)
                    };
                    return false
                })
            }
        })
    };


    // INDEX
    if ($('#ForumCenter_fmt.shortIndex').length) {
        var $IndexList = $(".sideBox[class$=List]");
        $IndexList.each(function() {
            var $Wrapper = $(this).find(".wrapper");
            if ($Wrapper.height() < 280) {
                $Wrapper.css("height", "auto");
                $(this).find(".more,.gradient").css("display", "none")
            } else {
                $Wrapper.css("height", "280px");
                $(this).find(".more").children("span").on("click", function() {
                    var $ParWrapper = $(this).parents('.sideBox').find('.wrapper'),
                        $CurHeight = $ParWrapper.height();
                    if ($CurHeight == 280) {
                        $ParWrapper.css('height', 'auto');
                        $ParWrapper.children(".gradient").fadeOut();
                        $(this).addClass('active')
                    } else {
                        $ParWrapper.animate({
                            height: 280
                        }, 200);
                        $ParWrapper.children(".gradient").fadeIn();
                        $(this).removeClass('active');
                        $('body, html').stop().animate({
                            scrollTop: $(this).parents('.sideBox').offset().top - 100
                        }, 600)
                    };
                    return false
                })
            }
        });
    } else {
        var $IndexList = $(".sideBox[class$=List]");
        $IndexList.each(function() {
            var $Wrapper = $(this).find(".wrapper");
            if ($Wrapper.height() < 500) {
                $Wrapper.css("height", "auto");
                $(this).find(".more,.gradient").css("display", "none")
            } else {
                $Wrapper.css("height", "500px");
                $(this).find(".more").children("span").on("click", function() {
                    var $ParWrapper = $(this).parents('.sideBox').find('.wrapper'),
                        $CurHeight = $ParWrapper.height();
                    if ($CurHeight == 500) {
                        $ParWrapper.css('height', 'auto');
                        $ParWrapper.children(".gradient").fadeOut();
                        $(this).addClass('active')
                    } else {
                        $ParWrapper.animate({
                            height: 500
                        }, 200);
                        $ParWrapper.children(".gradient").fadeIn();
                        $(this).removeClass('active');
                        $('body, html').stop().animate({
                            scrollTop: $(this).parents('.sideBox').offset().top - 100
                        }, 600)
                    };
                    return false
                })
            }
        });
    };


    // Search
    if ($('#search').length) {
        var $SearchList = $(".searchresults");
        $SearchList.each(function() {
            var $Wrapper = $(this).find("ul");
            if ($Wrapper.height() < 750) {
                $Wrapper.css("height", "auto");
                $(this).find(".more,.gradient").css("display", "none")
            } else {
                $Wrapper.css("height", "750px");
                $(this).find(".more").children("span").on("click", function() {
                    var $ParWrapper = $(this).parents('.searchresults').find('ul'),
                        $CurHeight = $ParWrapper.height();
                    if ($CurHeight == 750) {
                        $ParWrapper.css('height', 'auto');
                        $ParWrapper.children(".gradient").fadeOut();
                        $(this).addClass('active')
                    } else {
                        $ParWrapper.animate({
                            height: 750
                        }, 200);
                        $ParWrapper.children(".gradient").fadeIn();
                        $(this).removeClass('active');
                        $('body, html').stop().animate({
                            scrollTop: $(this).parents('.searchresults').offset().top 
                        }, 600)
                    };
                    return false
                })
            }
        })
    };


    // center landing pages
    if ($('#accordion').length){   
        $(".fold").each(function() { $(this).hide() });
        $("#accordion h3").eq(0).addClass("active");
        $(".fold").eq(0).show();
        $("#accordion h3").click(function() {
            $(".active").siblings(".fold").slideUp("slow");
            $(".active").removeClass("active");
            $(this).next(".fold").slideDown("slow");
            $(this).toggleClass("active");
        });
    };

    // slideshow landing page
    if ($('#slideShow-main').length){
        $("#slideShow-main #tabTitle").addClass("active");
        $("#slideShow-main .tabs").on("click", "#tabCate", function () {
            $(this).parents(".tabs").find("li a").removeClass("active");
            $("#viewTile").fadeOut(250);
            $("body, html")
                .stop()
                .animate({ scrollTop: $(this).offset().top - 120 }, 600);
            $(this).addClass("active");
            $("#viewCate").fadeIn(250);
        });
        $("#slideShow-main .tabs").on("click", "#tabTitle", function () {
            $(this).parents(".tabs").find("li a").removeClass("active");
            $("#viewCate").fadeOut(250);
            $("body, html")
                .stop()
                .animate({ scrollTop: $(this).offset().top - 120 }, 600);
            $(this).addClass("active");
            $("#viewTile").fadeIn(250);
        });
        $("#tileContainer li img").lazyload({ event: "turnPage", effect: "fadeIn" });
        $(".holder").jPages({
            containerID: "tileContainer",
            previous: ".icon-arrow-left2",
            next: ".icon-arrow-right2",
            perPage: 15,
            minHeight: false,
            keyBrowse: true,
            callback: function (pages, items) {
                $("#legend").html(pages.current + " / " + pages.count);
                items.showing.find("img").trigger("turnPage");
                items.oncoming.find("img").trigger("turnPage");
            },
        });
        $("#viewCate").accordion({ heightStyle: "content", collapsible: true, animate: 300 });
        $("#viewCate").on("click", "h3", function () {
            setTimeout(function () {
                $("body, html").animate({ scrollTop: $("h3.ui-accordion-header-active").offset().top - 100 }, 600);
            }, 310);
        });
    };








});