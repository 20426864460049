/**
 * custom jQuery plugin 'slideEmbed'
 * usage: add class name 'mni-js-slideEmbed' and data attribute 'data-embed-url' for the destination url
 */
(function ( $ ) {
    $.fn.slideEmbed = function () { 
		var modalIndex = 0;
    	return this.each(function() {
			$this = this;
			var s_slide_embed = $(this).data('embed-url');
			var s_slide_tracking = $(this).data('embed-tracking');
			if (!s_slide_embed || s_slide_embed === '') {
				console.log('mni-js-slideEmbed error: url not found');
			} else {
				if (navigator.userAgent.match(/(?=droid|ipad|nook|tablet|silk)(?!mobile)/i)) { 
					$(this).on('click', function () { 
						window.open(s_slide_embed) 
					})
				} else { 
					var slideModal = new modal({ 
						elem: this, 
						content: '<iframe src="' + s_slide_embed + '?embed=1" marginwidth="0" marginheight="0" frameborder="0" scrolling="no" />', 
						height: '800', 
						width: '920', 
						track: s_slide_tracking || '' 
					}); 
					slideModal.init(); 
				}
			}
		});
    };
}( jQuery ));

$(document).ready(function() {
    $('.mni-js-slideEmbed').slideEmbed();
});