stickyBannerAd = true;
$(document).ready(function() {
    $('a[rel=slide]').addClass('slidelink');
    $('a[rel=slide]').attr('title', 'Educational Slideshow');
    $('a[rel=slide]').attr('target', '_blank');
    $('a[rel=quiz]').addClass('quizlink');
    $('a[rel=quiz]').attr('title', 'Learning Quiz');
    $('a[rel=quiz]').attr('target', '_blank');
    $('a[rel=img]').addClass('imglink');
    $('a[rel=img]').attr('title', 'Medical Image');
    $('a[rel=img]').attr('target', '_blank');
    $('a[rel=dict]').addClass('dictlink');
    $('a[rel=dict]').attr('title', 'Definition');
    $('a[rel=dict]').attr('target', '_blank');
    $('a[href="#"]').click(function(e) {
        e.preventDefault()
    });
    $('#menu').on('mouseenter', '.mainList>li>a', function() {
        var $this = $(this),
            $active = $('#menu').find('.mainList li a.active').not($this);
        $active.removeClass('active');
        $active.next('.submenu').stop().fadeOut(200);
        $this.addClass('active');
        $this.next('.submenu').stop().fadeIn(100)
    });
    $('#menu').on('mouseleave', '.mainList li', function() {
        var $this = $(this);
        $this.find('.submenu').stop().fadeOut(200);
        $this.find('a.active').removeClass('active')
    });
    // setup sticky menu and ad banner 
    //Changing logic to be sticky 100% for all content types
    if (1==0) {// (s_articletype != 'slide' && s_articletype != 'quiz') {
        setTimeout(function(){
            $('.adTopWrapper').removeClass('sticky');
            stickyBannerAd = false;
        },10000);
    }
    // setTimeout(function() {
    //     if (!$('#bannerAd_rdr').hasClass('pushdown')) {
    //         stickyBannerAd = true;
    //         Waypoint.refreshAll();
    //     }
    // },1000);
    var miniMenuWaypoint = $('body').waypoint({
        handler: function(direction) {
          if (direction === 'down') {
            // $('#menu').addClass('mini').find('.stash').show();
            if (stickyBannerAd) {
              $('.adTopWrapper').addClass('sticky');
            }
          } else {
            // $('#menu').removeClass('mini').find('.stash').hide();
            if (stickyBannerAd) {
              $('.adTopWrapper').removeClass('sticky');
            }
          }
        },
        offset: -110
    });
    $('#menu').on('click', '.search', function() {
        var $top = parseInt($('#slideSearch').css('top'));
        if ($top < 0) {
            $('#slideSearch').stop().animate({
                top: 41
            }, 300)
        } else {
            $('#slideSearch').stop().animate({
                top: -110
            }, 600)
        }
    });
    $('.topSlide, main').on('mouseenter', function() {
        $('#slideSearch').stop().animate({
            top: -110
        }, 600)
    })
});
