stickyBannerAd = true;


$(document).ready(function() {

    // crosslink
    $('a[rel=slide]').addClass('slidelink');
    $('a[rel=slide]').attr('title', 'Educational Slideshow');
    $('a[rel=slide]').attr('target', '_blank');

    $('a[rel=quiz]').addClass('quizlink');
    $('a[rel=quiz]').attr('title', 'Learning Quiz');
    $('a[rel=quiz]').attr('target', '_blank');

    $('a[rel=img]').addClass('imglink');
    $('a[rel=img]').attr('title', 'Medical Image');
    $('a[rel=img]').attr('target', '_blank');

    $('a[rel=dict]').addClass('dictlink');
    $('a[rel=dict]').attr('title', 'Definition');
    $('a[rel=dict]').attr('target', '_blank');


    // no jump on # 
    $('a[href="#"]').click(function(e) {
        e.preventDefault()
    });


    // Masterhead
    $('body>header').append('<div class=shade></div>');

    $('.masterhead .search').on('click', 'span', function() {
        $(this).parents('body').toggleClass('freeze');
        $(this).parents('.masterhead').find('.menu span, .socialWrapper').fadeToggle();
        $(this).toggleClass('icon-close');
        $(this).parents('.masterhead').siblings('.searchBar, .shade').toggleClass('on');
    });

    $('.masterhead .menu').on('click', 'span', function() {
        $(this).parents('body').toggleClass('freeze');
        $(this).parents('.masterhead').find('.search span, .socialWrapper').fadeToggle();
        $(this).toggleClass('icon-close');
        $(this).parents('.masterhead').siblings('.masterSub, .shade').toggleClass('on');
    });

    $('.masterSub .stack').hover(function() {
        $(this).siblings().removeClass('on');
        $(this).addClass('on');
    }, function() {
        $(this).removeClass('on');
    });

    $('body>header').on('click', '.shade', function() {
        $(this).parents('body').removeClass('freeze');        
        $(this).siblings('.masterhead').find('.menu span, .search span, .socialWrapper').removeClass('icon-close').fadeIn();        
        $(this).parents('body').find('#headline .miniBtn').children('.icon-search, .icon-menu').removeClass('icon-close').css("display", "");
        $(this).siblings('.masterSub, .searchBar').removeClass('on');
        $(this).removeClass('on');
    });


    // sticky top AD
    if (s_articletype != 'slide' && s_articletype != 'quiz') {
        setTimeout(function() {
            $('.adTopWrapper').removeClass('sticky');
            stickyBannerAd = false;
        }, 20000);
    }
    var miniMenuWaypoint = $('body').waypoint({
        handler: function(direction) {
            if (direction === 'down') {
                if (stickyBannerAd) {
                    $('.adTopWrapper').addClass('sticky');
                }
            } else {
                if (stickyBannerAd) {
                    $('.adTopWrapper').removeClass('sticky');
                }
            }
        },
        offset: -110
    });


    // Suppress tooltip
    if ($('#article-wrapper-temp').length !== 1) {
        $('a').mouseover(function() {
            $(this).attr('data-title', $(this).attr('title'));
            $(this).removeAttr('title')
        })
    };


    // Back to Top
    $(window).scroll(function() {
        if ($(window).scrollTop() > window.innerHeight) {
            $('#backTop').fadeIn();
            $('#backTop').on('click', function() {
                $('body, html').scrollTop(0)
            })
        } else {
            $('#backTop').fadeOut()
        }
    });



});